/* Calendar */

$pickupEventText: $purpleHl;
$pickupEventBg: rgba(lighten($pickupEventText, 36%), .8);

$venueEventText: $greenHl;
$venueEventBg: rgba(lighten($venueEventText, 47%), .8);

$campEventText: $blueHl;
$campEventBg: rgba(lighten($campEventText, 37%), .8);

.calendar-week {

    border-top: 1px solid $light2;
    // padding: 5px;

    //styles here
    .calendar_default_main {

        font-family: $regular;
        border: none;

        &>div:nth-child(2) {
            height: 620px !important;
        }

        &>div:nth-child(2)>div>table>tbody>tr,
        &>div:nth-child(1)>table>tbody>tr {
            display: flex;
        }

        &>div:nth-child(1)>table {
            overflow: hidden;
        }

        &>div:nth-child(1)>table>tbody>tr {

            &>td:first-child .calendar_default_corner_inner,
            &>td:last-child .calendar_default_cornerright_inner {
                border: none;
                background-color: rgba($light1, .5);
                border-bottom: 1px solid $light2;
                border-right: 1px solid $light2;
                height: 80px !important;
                box-sizing: border-box;
            }

        }

        &>div:nth-child(2)>div>table {

            background-color: transparent;

            &>tbody>tr>td:nth-child(2)>div {
                border-bottom-left-radius: 50px;
                overflow: hidden;
                background-color: $light0;
            }
        }
    }

    .calendar_default_colheader {
        height: 80px !important;
    }

    .calendar_default_colheader_inner {
        background: rgba($light1, 50%);
        border: none;
        border-bottom: 1px solid $light2;

        div {
            display: flex;
            align-items: center;
            flex-direction: column;
            color: $dark2;

            &.active {
                color: $primary;
            }
        }

        .day {
            font-size: 22px;
            font-weight: 600;
        }

        .day-name {
            text-transform: uppercase;
        }
    }

    .calendar_default_rowheader_inner {
        background: rgba($light1, .5);
        border: none;
        border-right: 1px solid $light2;
        color: $dark2;
        font-size: 14px;
        padding-right: 10px;
    }

    .calendar_default_cell_inner {
        background-color: transparent !important;
        border-color: $light2;
    }

    .calendar_default_cornerright_inner {
        background-color: $light1 !important
    }

    .calendar_default_event {
        box-sizing: border-box;
    }

    .calendar_default_event_inner {
        border: none;
        background: transparent;
        inset: 5px 0 5px 5px;
        padding: 0;
        font-size: 14px;
        overflow: visible;
    }

    .calendar_default_event_bar {
        display: none;
    }

    .calendar_default_shadow_inner {
        background-color: $light5;
    }

    .event_now {
        height: 2px !important;
        padding: 0px !important;
        background-color: $primary;
        position: relative;

        &:before {
            content: '';
            position: absolute;
            top: -1px;
            left: 0;
            width: 4px;
            height: 4px;
            background-color: $primary;
            border-radius: 50%;
            z-index: 1;
        }
    }

    .event_custom_inner {
        display: flex;
        flex-direction: column;
        height: 100%;
        padding: 5px;
        padding-left: 10px;
        box-sizing: border-box;
        color: $primary;
        border-radius: 7px;

        .event_custom_title {
            font-weight: 600;
            color: currentColor;
            max-height: 2.8rem;
            overflow: hidden;
            text-overflow: ellipsis;
            line-height: 1.4;
        }

        .event_custom_time {
            font-weight: 600;
            color: currentColor;
        }

        .event_custom_location {
            color: rgba($dark2, .8);
            flex-grow: 1;
        }

        &.CampSession {
            background-color: $campEventBg;
            border-color: $campEventText;
            color: $campEventText;
        }

        &.PickupSession {
            background-color: $pickupEventBg;
            border-color: $pickupEventText;
            color: $pickupEventText;
        }

        &.VenueBooking {
            background-color: $venueEventBg;
            border-color: $venueEventText;
            color: $venueEventText;
        }
    }
}