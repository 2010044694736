@use 'sass:math';
@import "./src/styles/Library";
.link {
    position: fixed;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    bottom: 34px;
    right: 160px;
    color: $light0;
    background: $dark1;
    padding: 8px 20px;
    border-radius: 20px 3px 20px 20px;
    z-index: 2147483647;
    font-size: 1rem;
    font-weight: 600;
    letter-spacing: .5px;
    transition: .5s ease all;
}

.link:hover {
    color: $light0;
    background: $alt;
    transform: scale(1.1);
}

.icon {
    padding-right: 4px;
    font-size: 1.2em;
}