@use 'sass:math';
@import "./src/styles/Library";
/* App */

/*
    Project customizations here
    Overides default dash styles
*/

#root #page:after {
    content: "";
    display: block;
    height: 80px;
}

.panel .panel.index-table {
    box-shadow: none;
    background: none;
    border-radius: 0;
}