// Overwrite Variables Here

$primary: #ff4600;
$secondary:#fdb82b;
$alt:#0dc16d;

$primaryLight: #FDF1E7;
$primaryDark: #48250A;

$sidebarBg: #242134;

$light0: white;
$light1: #f9f9f9;
$light2: #eff2f6;
$light3: #dfe4ea;
$light4: #c6c6c8;
$light5: #bfbfbf;
$light6: #bdbdbd;

$dark0: black;
$dark1: #383b3c;
$dark2: #595c5e;
$dark3: #6f7781;