@use 'sass:math';
@import "./src/styles/Library";
.bracketWrap {
    background: transparent;
}

.bracketInner {
    display: flex;
}

.bracketScroll {}

.column {
    display: flex;
    flex-direction: column;
    min-width: 190px;
    flex: 1;
    justify-content: space-around;
    position: relative;
    padding: 10px 0 0;
    margin: 0 10px 0;
}

.columnFirst {
    margin-left: 0;
}

.columnLast {
    margin-right: 0;
}

.columnRtl {}

.columnTitle {
    text-align: center;
    position: absolute;
    top: 0;
    width: 100%;
    color:$dark3;
}

.columnSize1 {}


// // Item

.item {
    margin: 25px 0;
    background-color: $panelBg;
    border-radius: 5px;
    border: 1px solid $panelBdr;
    box-shadow: 2px 2px 2px rgba($panelBdr, 60%);
    position: relative;
    cursor: pointer;
}

.itemMatchupKey {
    position: absolute;
    left: 0;
    width: 100%;
    text-align: center;
    bottom: -20px;
    font-size: 11px;
    color:$dark3;
}

.itemContainer {
    display: flex;
    padding: 8px 0;
}

.itemContainerLoser {
    background-color: $bodyBg;
}

.separator {
    height: 1px;
    background-color: $panelBdr
}

.itemSeed {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 2.5rem;
    flex-shrink: 0;
}

.seedText {
    color: $dark3;
}

.itemContent {
    display: flex;
    align-items: center;
    flex: 1;
}

.logo {
    width: 2.4rem;
    height: 2.4rem;
    margin-right: 8px;
    border-radius: 50%
}

.name {
    color: $dark2;
    margin-right: 1rem;
    font-size: 1rem;
    flex-grow: 2;
    line-height: 1.2em;
    max-height: 2.4em;
    overflow: hidden;
}

.score {
    font-weight: 600;
    color: $dark0;
    margin-right: 1rem;
    font-size: 1.2rem;
}