@use 'sass:math';
@import "./src/styles/Library";

.slots {
    display: flex;
    flex-wrap: wrap;
    gap: .8rem;
}

.slot {
    color: $dark3;
    font-size: 15px;
    font-weight: 600;
    background-color: $light2;
    border-radius: 5px;
    flex-basis: 100px;
    line-height: 35px;
    flex-grow: 1;
    text-align: center;
    cursor: pointer;

    &:hover {
        background-color: $light3;
    }
}

.slotsDisabled {
    @extend .slot;
    opacity: .5;

    &:hover {
        @extend .slot;
        cursor: not-allowed;
    }
}

.slotSelected {
    background-color: $primary;
    color: $light0;

    &:hover {
        background-color: $primary;
    }
}

.info {
    font-size: 12px;
    margin-top: 20px;
}